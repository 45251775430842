import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "status": "draft",
  "path": "/me-and-my-dad/",
  "heroImage": "dad.jpg",
  "title": "In Memory of Dr. Michael Wong (1946 - 2022)",
  "description": "My Father, My Role Model",
  "date": "2022-06-10T00:00:00.000Z",
  "tags": ["family", "personal"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Last Friday my father passed away after a 6 year battle with frontotemporal dementia. He was 75 years old.`}</p>
    <p>{`Most people know my father as a pediatrician at the Corvallis clinic for 37 years, who cared for thousands of children over the years and has even seen the second generation of his early patients, but to me he was always just, Dad. And even though he cared for so many children, he was always there for me and always knew how to get me to crack a smile with his rolodex of corny dad jokes.`}</p>
    <p>{`I can't believe you're gone. I can't wait to see and laugh with you again. I can only hope to raise my 3 boys with the same unconditional love you raised with me and my brothers; you set the bar high! Love you dad.`}</p>
    <p>{`Please consider a donation to the Bluefield Project to help accelerate the search for a cure for frontotemporal dementia (FTD). The Bluefield Project is a 501(c)(3) organization. `}<a parentName="p" {...{
        "href": "https://www.bluefieldproject.org/donate/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`https://www.bluefieldproject.org/donate/`}</a></p>
    <p>{`I can't tell you the countless times I will meet a random stranger in town who will tell me "Your dad was my pediatrician growing up and saved my hand as a kid, or your father saved my daughter's life from spinal meningitis." It gives me great joy knowing the positive impact my father has had on this community and the number of lives he has touched over the years.`}</p>
    <p>{`My father's older sister also shared some stories about how my father was always her proctector from their early childhood years all the way into adulthood. It was innate in my father to always put other first, to be truly selfless. I take comfort in the fact that I know he's still watching out for his family and friends from above. My own boys who are obsessed with superheroes who protect people from bad guys is a`}</p>
    <h3>{`My Role Model`}</h3>
    <p>{`My parents had three boys (I am the youngest) and growing up there was always the requisite fighting I grew up with two older brothers and we tormented our Dad and Mom with countless fights. Now I have three boys of my own.`}</p>
    <h3>{`The Battle`}</h3>
    <p>{`The last six years of my dad's life was a battle with frontotemporal dementia. The worst possible form of dementia that robs a person of their mind, their body and their family. It was incredibly difficult to watch my dad fight a losing battle with this degenerative disease, but I have no doubt in my mind that he continued to love and care for his family and friends to the very end. That's just the kind of man he was; selfless and always concerned for the well-being of others, no matter what was he was going through.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.796875%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAUGAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABY8ijSURLEv8A/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECAyIj/9oACAEBAAEFAtLmVGkjaHbFXnb6/8QAFhEAAwAAAAAAAAAAAAAAAAAAEBJB/9oACAEDAQE/AWg//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABkQAAIDAQAAAAAAAAAAAAAAAAABERIhMf/aAAgBAQAGPwKs6XOibc4QM//EABoQAQADAQEBAAAAAAAAAAAAAAEAESFBgXH/2gAIAQEAAT8htGjMITVvs6wexq9TplXwImzez//aAAwDAQACAAMAAAAQ5+//xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8Q0CJ//8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxAL1tv/xAAaEAEBAAMBAQAAAAAAAAAAAAABEQAhMUFh/9oACAEBAAE/ECYUn0Q7g4xhpOh1x+4lGsUoNYuDQOkqS44Svpfrm/iIrn//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Dad at My Wedding",
          "title": "Dad at My Wedding",
          "src": "/static/05f785d637b7483202d5248cbfaecbdf/72e01/dad_and_me.jpg",
          "srcSet": ["/static/05f785d637b7483202d5248cbfaecbdf/e4a55/dad_and_me.jpg 256w", "/static/05f785d637b7483202d5248cbfaecbdf/36dd4/dad_and_me.jpg 512w", "/static/05f785d637b7483202d5248cbfaecbdf/72e01/dad_and_me.jpg 1024w", "/static/05f785d637b7483202d5248cbfaecbdf/ac99c/dad_and_me.jpg 1536w", "/static/05f785d637b7483202d5248cbfaecbdf/d9c39/dad_and_me.jpg 1800w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "64.84375%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABv1zKRIgT/8QAGxAAAgEFAAAAAAAAAAAAAAAAAQIRABMhIzH/2gAIAQEAAQUCkEN1skwzs+u7Ff/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AUf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAABBAIDAAAAAAAAAAAAAAAAAQIRQRAhMTJR/9oACAEBAAY/AlbcExWHt8byJqjoh//EABsQAQADAAMBAAAAAAAAAAAAAAEAESFBUYGR/9oACAEBAAE/IU5U0vqO+Nz7llajTjk0dZXjqmeRGD6M/9oADAMBAAIAAwAAABCz3//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/EIP/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBX/8QAHBABAQADAAMBAAAAAAAAAAAAAREAITFBYXHw/9oACAEBAAE/EE2YUPKJjBSYv4cgPfowg50Z4aWc3zIekCl3Q4hoT8dz/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Dad and Young Me",
          "title": "Dad and Young Me",
          "src": "/static/100ddcf7d5b8ebe8d70edd35a84bdb93/72e01/dad_and_young_me.jpg",
          "srcSet": ["/static/100ddcf7d5b8ebe8d70edd35a84bdb93/e4a55/dad_and_young_me.jpg 256w", "/static/100ddcf7d5b8ebe8d70edd35a84bdb93/36dd4/dad_and_young_me.jpg 512w", "/static/100ddcf7d5b8ebe8d70edd35a84bdb93/72e01/dad_and_young_me.jpg 1024w", "/static/100ddcf7d5b8ebe8d70edd35a84bdb93/ac99c/dad_and_young_me.jpg 1536w", "/static/100ddcf7d5b8ebe8d70edd35a84bdb93/bcd4e/dad_and_young_me.jpg 1632w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "64.84375%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQBBf/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABu2GyOYDX/8QAHBAAAgICAwAAAAAAAAAAAAAAAQIAEQMEEhMh/9oACAEBAAEFAnfyiIdg2XPVka8XJJ//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwFH/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/AY1//8QAHRAAAQQCAwAAAAAAAAAAAAAAAAECESEDMSJigf/aAAgBAQAGPwJkJujkaQxr2HxReOfT/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIVFBcf/aAAgBAQABPyFG7V4j5mS5o/s6BNwEXmb6x//aAAwDAQACAAMAAAAQZ8//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQBB/9oACAEDAQE/EELNBzf/xAAXEQEBAQEAAAAAAAAAAAAAAAABABFR/9oACAECAQE/EEptrt//xAAcEAEBAAMAAwEAAAAAAAAAAAABEQAhMVFhcYH/2gAIAQEAAT8QQUaAzcypGjzL7wwCHClyP6D5+ZtLh0a0mAClqovfmf/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Wong Gangstas",
          "title": "Wong Gangstas",
          "src": "/static/1bbbbd3b5d6bffafc56b8e59e453bdf6/72e01/wong_gangstas.jpg",
          "srcSet": ["/static/1bbbbd3b5d6bffafc56b8e59e453bdf6/e4a55/wong_gangstas.jpg 256w", "/static/1bbbbd3b5d6bffafc56b8e59e453bdf6/36dd4/wong_gangstas.jpg 512w", "/static/1bbbbd3b5d6bffafc56b8e59e453bdf6/72e01/wong_gangstas.jpg 1024w", "/static/1bbbbd3b5d6bffafc56b8e59e453bdf6/ac99c/wong_gangstas.jpg 1536w", "/static/1bbbbd3b5d6bffafc56b8e59e453bdf6/bcd4e/wong_gangstas.jpg 1632w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABYBAQEBAAAAAAAAAAAAAAAAAAMAAv/aAAwDAQACEAMQAAAB1RyofTRrP//EABkQAAIDAQAAAAAAAAAAAAAAAAACAQMTEv/aAAgBAQABBQLpDtDRCx8hLJltD//EABURAQEAAAAAAAAAAAAAAAAAABAS/9oACAEDAQE/AZP/xAAXEQEAAwAAAAAAAAAAAAAAAAAAARES/9oACAECAQE/AdSt/8QAGhAAAgIDAAAAAAAAAAAAAAAAAEEBkRAhUf/aAAgBAQAGPwJ0Oh0bJ5OP/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARITFR/9oACAEBAAE/ITlAe0wZcN/JIkdvhEi0TC+RVz//2gAMAwEAAgADAAAAEIc//8QAGBEBAQADAAAAAAAAAAAAAAAAAQARQVH/2gAIAQMBAT8QDssHL//EABcRAAMBAAAAAAAAAAAAAAAAAAABURH/2gAIAQIBAT8QkzdP/8QAGhABAAIDAQAAAAAAAAAAAAAAAQARIVFxgf/aAAgBAQABPxCyID0wm5YQVLC0t44YoUAnWcxQ2CGGh5CR/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Crabbing with Dad",
          "title": "Crabbing with Dad",
          "src": "/static/ce9cebd901544e9fae14eeb4cfb589de/72e01/crabbing_with_dad.jpg",
          "srcSet": ["/static/ce9cebd901544e9fae14eeb4cfb589de/e4a55/crabbing_with_dad.jpg 256w", "/static/ce9cebd901544e9fae14eeb4cfb589de/36dd4/crabbing_with_dad.jpg 512w", "/static/ce9cebd901544e9fae14eeb4cfb589de/72e01/crabbing_with_dad.jpg 1024w", "/static/ce9cebd901544e9fae14eeb4cfb589de/ac99c/crabbing_with_dad.jpg 1536w", "/static/ce9cebd901544e9fae14eeb4cfb589de/ba579/crabbing_with_dad.jpg 1680w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "107.421875%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAVABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMEAgX/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAGnOaGF+ogT0QKQE//EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMREhP/2gAIAQEAAQUCewKEuDjoGOhK1Lj+ZhXYUUTvJ//EABcRAAMBAAAAAAAAAAAAAAAAAAABERD/2gAIAQMBAT8BrK8p/8QAGBEAAgMAAAAAAAAAAAAAAAAAABIBEBH/2gAIAQIBAT8BSMFv/8QAHBAAAwABBQAAAAAAAAAAAAAAABEhAQIQMUFR/9oACAEBAAY/Ankhyik8Jq27Ej//xAAbEAADAQEBAQEAAAAAAAAAAAAAAREhMVFB0f/aAAgBAQABPyF7dycYyeOoX6Aa1Tmmq0tOicrgITaNate0y6T62f/aAAwDAQACAAMAAAAQ2Aj9/8QAFxEBAQEBAAAAAAAAAAAAAAAAAAERYf/aAAgBAwEBPxB81rT/xAAYEQACAwAAAAAAAAAAAAAAAAAAARARMf/aAAgBAgEBPxBayo4//8QAGxABAAMBAQEBAAAAAAAAAAAAAQARITFhQZH/2gAIAQEAAT8QEQjodLirVcJie+kKWu6pW+8hgBk3XCKOhh8bBGvpsoyBd4xQ2/xx/IUuBXQz/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Dad and Bear",
          "title": "Dad and Bear",
          "src": "/static/48b1b4a0365168dc9f154d82518a6a33/72e01/dad_and_bear.jpg",
          "srcSet": ["/static/48b1b4a0365168dc9f154d82518a6a33/e4a55/dad_and_bear.jpg 256w", "/static/48b1b4a0365168dc9f154d82518a6a33/36dd4/dad_and_bear.jpg 512w", "/static/48b1b4a0365168dc9f154d82518a6a33/72e01/dad_and_bear.jpg 1024w", "/static/48b1b4a0365168dc9f154d82518a6a33/f9218/dad_and_bear.jpg 1333w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`SUPPORT THE BLUEFIELD PROJECT`}</h3>
    <p>{`Please consider a donation to the Bluefield Project to help accelerate the search for a cure for frontotemporal dementia (FTD). The Bluefield Project is a 501(c)(3) organization.`}</p>
    <a href="https://www.bluefieldproject.org/donate/" target="_blank">
  <button style={{
        "background": "#0086c9",
        "color": "white",
        "fontWeight": "bold",
        "padding": ".2rem .8rem",
        "borderRadius": "1rem",
        "margin": "1rem 0"
      }}>
    Donate Now
  </button>
    </a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      